function modalSetup() {
  const element = document.getElementById('new-charting-notification-button');

  if (element) {
    element.addEventListener('click', (event) => {
      const doNotDisplayAgain = !document.getElementById('do-not-display-again').checked;

      updateProfile(doNotDisplayAgain);
    })
  }
}

function updateProfile(show_new_charting_notification) {
  return fetch(
    '/app/profile',
    {
      body: JSON.stringify({ user: { show_new_charting_notification }}),
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      method: 'PUT',
    },
  )
  .catch((result) => console.log(result));
}

document.addEventListener('turbolinks:load', () => {
  modalSetup()
});
