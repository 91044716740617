import React, { useState } from 'react';
import Header from './Table/Header';
import Body from './Table/Body';

export default function Table({
    abdominalPains,
    addFup,
    chart,
    dischargeObservations,
    dischargeObservationModifiers,
    essentialSameness,
    fupActive,
    keys,
    intercourses,
    menstrualFlows,
    medications,
    observationFrequencies,
    pdf,
    pointOfChangeAnswers,
    sameAsYesterdayAnswers,
    scale,
    setAddFup,
    stickers,
    subscriptionActive,
}) {
  const [managedChart, setManagedChart] = useState(chart);
  const managedChartDays = managedChart.chart_cycles.flatMap(({ chart_days }) => chart_days);

  const pushDayToChart = (previousId, day) => {
    const updatedCycles = managedChart.chart_cycles.map((cycle) => {
      const previousDay = cycle.chart_days.find(({ id }) => id === previousId);

      if (previousDay) {
        const updatedDays = [
          // remove previous record
          ...cycle.chart_days.filter(({ id }) => id !== previousId),
          // add updated previous record
          { ...previousDay, current_chart_day: false },
          // add newly created day
          day,
        ];

        return {
          ...cycle,
          chart_days: updatedDays,
        };
      } else {
        return cycle;
      }
    });

    setManagedChart({
      ...managedChart,
      chart_cycles: updatedCycles,
    });
  };

  const pushDay = (previousId, day) => {
    pushDayToChart(previousId, day);
  };

  const [managedMedications, setManagedMedications] = useState(medications);
  const pushMedication = (medication) => setManagedMedications([medication, ...managedMedications.filter(({ id }) => medication.id !== id)])

  const [managedPmss, setManagedPmss] = useState(pmss);
  const pushPms = (pms) => setManagedPmss([...managedPmss.filter(({ id }) => pms.id !== id), pms])

  return (
    <div className="table-responsive" style={{ overflowX: 'visible', transformOrigin: 'top left', transform: `scale(${scale})`}}>
      <table className="table table-bordered">
        <Header
          chart={chart}
        />
        <Body
          abdominalPains={abdominalPains}
          addFup={addFup}
          chart={managedChart}
          chartDays={managedChartDays}
          dischargeObservations={dischargeObservations}
          dischargeObservationModifiers={dischargeObservationModifiers}
          essentialSameness={essentialSameness}
          fupActive={fupActive}
          keys={keys}
          intercourses={intercourses}
          medications={managedMedications}
          menstrualFlows={menstrualFlows}
          observationFrequencies={observationFrequencies}
          pdf={pdf}
          pmss={managedPmss}
          pointOfChangeAnswers={pointOfChangeAnswers}
          pushDay={pushDay}
          pushMedication={pushMedication}
          pushPms={pushPms}
          sameAsYesterdayAnswers={sameAsYesterdayAnswers}
          setAddFup={setAddFup}
          stickers={stickers}
          subscriptionActive={subscriptionActive}
        />
      </table>
    </div>
  )
}
