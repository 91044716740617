import React, { useRef, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import {
  captureClick,
  parseDate,
} from '../../ChartUtilities';

const steps = ['date', 'menstrual_flow', 'discharge_observation', 'discharge_observation_modifiers', 'observation_frequency', 'intercourse'];

export default function CodeEntry({
  correctedDay,
  day,
  dischargeObservations,
  dischargeObservationModifiers,
  essentialSameness,
  form,
  fupActive,
  intercourses,
  menstrualFlows,
  observationFrequencies,
  pointOfChangeAnswers,
  sameAsYesterdayAnswers,
  submitForm,
}) {
  const flatpickr = useRef(null);
  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    setCurrentStep((currentStep + 1) % 6)
  }
  const previousStep = () => {
    setCurrentStep((currentStep + 5) % 6)
  }

  const onDateUpdate = ([date]) => {
    if (date) {
      const recordingDate = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
      form.setValue('recording_date', recordingDate)
      nextStep();
    } else {
      form.setValue('recording_date', null)
    }
  }

  const dischargeObservationIds = form.watch('discharge_observation_ids');
  const dischargeObservationModifierIds= form.watch('discharge_observation_modifier_ids');
  const forgotDay = form.watch('forgot_day');
  const intercourseId = form.watch('intercourse_id');
  const menstrualFlowIds = form.watch('menstrual_flow_ids');
  const observationFrequencyId = form.watch('observation_frequency_id');
  const pointOfChangeAnswerId = form.watch('point_of_change_answer_id');
  const sameAsYesterdayAnswerId = form.watch('same_as_yesterday_answer_id');

  // singular id
  const toggleCode = (field, value) => {
    form.setValue('correction', fupActive)
    if (form.getValues(field) === value) {
      form.setValue(field, null);
    } else {
      form.setValue(field, value);
    }
  }

  // plural ids
  const toggleCodes = (field, value) => {
    const currentIds = form.getValues(field);

    form.setValue('correction', fupActive)
    if (currentIds.includes(value)) {
      const updatedIds = currentIds.filter((id) => id !== value);

      form.setValue(field, updatedIds);
    } else {
      form.setValue(field, [...currentIds, value]);
    }
  }

  const showCode = (code) => {
    return fupActive || !code.fup_only;
  }

  const sortCode = (left, right) => {
    return left.sort_order - right.sort_order;
  }

  return (
    <div className="daily-entry shadow border mt-2 rounded">
      <form
        onClick={captureClick}
        onSubmit={submitForm}
      >
        <div className="vdrs-step vdrs-styled-check col">
          {form.watch('recording_date') || steps[currentStep] !== 'date'
            ? <div className="row mx-1 my-1 d-flex justify-content-between align-items-center">
                <i className="fa fa-chevron-left px-4 py-2" role="button" onClick={previousStep} disabled={currentStep === 0} />
                <button className="bg-transparent border-0" onClick={submitForm}>Submit</button>
                <i className="fa fa-chevron-right px-4 py-2" role="button" onClick={nextStep} disabled={currentStep === 5} />
              </div>
            : null
          }
        </div>
          {/* A */}
          {steps[currentStep] === 'date'
            ? <div className="row">
                <Flatpickr
                  value={form.getValues('recording_date')}
                  onChange={onDateUpdate}
                  options={{
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "Y-m-d",
                    enableTime: false,
                    inline: true,
                    parseDate,
                    static: true,
                    wrap: true,
                  }}
                  ref={flatpickr}
                >
                  <button
                    className="position-absolute bg-transparent border-0 mt-2"
                    style={{ right: '5px' }}
                    onClick={() => flatpickr?.current?.flatpickr?.clear()}>
                    Clear
                  </button>
                  <input className="form-control" data-input />
                </Flatpickr>
              </div>
            : null
          }
          {/* B */}
          {steps[currentStep] === 'menstrual_flow'
            ? <div className="row pt-2 code-selector">
                {menstrualFlows.filter(showCode).toSorted(sortCode).map((menstrualFlow) => (
                  <label
                    key={menstrualFlow.id}
                    className={`border-top d-flex m-0 p-3 w-100 ${menstrualFlow.fup_only ? 'fup_only' : ''}`}
                    onClick={() => toggleCodes('menstrual_flow_ids', menstrualFlow.id)}
                  >
                    <i className={`fa fa-check ${!menstrualFlowIds.includes(menstrualFlow.id) ? 'invisible': ''}`} />
                    <div className="flex-1 pl-2">
                      <span className="font-weight-bold">{menstrualFlow.abbreviation}</span> - <span>{menstrualFlow.name}</span>
                    </div>
                  </label>
                ))}
              </div>
            : null
          }
          {/* C */}
          {steps[currentStep] === 'discharge_observation'
            ? <div className="row pt-2 code-selector">
                {dischargeObservations.filter(showCode).toSorted(sortCode).map((dischargeObservation) => (
                  <label
                    key={dischargeObservation.id}
                    className={`border-top d-flex m-0 p-3 w-100 ${dischargeObservation.fup_only ? 'fup_only' : ''}`}
                    onClick={() => {
                      toggleCodes('discharge_observation_ids', dischargeObservation.id);
                      nextStep();
                    }}
                  >
                    <i className={`fa fa-check ${!dischargeObservationIds.includes(dischargeObservation.id) ? 'invisible' : ''}`} />
                    <div className="flex-1 pl-2">
                      <span className="font-weight-bold">{dischargeObservation.abbreviation}</span> - <span dangerouslySetInnerHTML={{__html: dischargeObservation.name}} />
                    </div>
                  </label>
                ))}
              </div>
            : null
          }
          {/* D */}
          {steps[currentStep] === 'discharge_observation_modifiers'
            ? <div className="row pt-2 code-selector">
                {dischargeObservationModifiers.filter(showCode).toSorted(sortCode).map((dischargeObservationModifier) => (
                  <label
                    key={dischargeObservationModifier.id}
                    className={`border-top d-flex m-0 p-3 w-100 ${dischargeObservationModifier.fup_only ? 'fup_only' : ''}`}
                    onClick={() => toggleCodes('discharge_observation_modifier_ids', dischargeObservationModifier.id)}
                  >
                    <i className={`fa fa-check ${!dischargeObservationModifierIds.includes(dischargeObservationModifier.id) ? 'invisible' : ''}`} />
                    <div className="flex-1 pl-2">
                      <span className="font-weight-bold">{dischargeObservationModifier.abbreviation}</span> - <span>{dischargeObservationModifier.name}</span>
                    </div>
                  </label>
                ))}
              </div>
            : null
          }
          {/* E */}
          {steps[currentStep] === 'observation_frequency'
            ? <div className="row pt-2 code-selector">
                {observationFrequencies.filter(showCode).toSorted(sortCode).map((observationFrequency) => (
                  <label
                    key={observationFrequency.id}
                    className={`border-top d-flex m-0 p-3 w-100 ${observationFrequency.fup_only ? 'fup_only' : ''}`}
                    onClick={() => {
                      toggleCode('observation_frequency_id', observationFrequency.id);
                      nextStep();
                    }}
                  >
                    <i className={`fa fa-check ${observationFrequencyId != observationFrequency.id ? 'invisible' : ''}`} />
                    <div className="flex-1 pl-2">
                      <span className="font-weight-bold">{observationFrequency.abbreviation}</span> - <span>{observationFrequency.name}</span>
                    </div>
                  </label>
                ))}
              </div>
            : null
          }
          {/* F */}
          {steps[currentStep] === 'intercourse'
            ? <div className="row py-2 px-3 code-selector">
                <label className="mx-3">Intercourse</label>
                <div className="col-12">
                  {intercourses.filter(showCode).toSorted(sortCode).map((intercourse) => (
                    <label
                      key={intercourse.id}
                      className={`btn btn-grey mr-1 ${intercourse.id == intercourseId && 'active'}`}
                      onClick={() => toggleCode('intercourse_id', intercourse.id)}
                    >
                      <span>{intercourse.name}</span>
                    </label>
                  ))}
                </div>
                {essentialSameness
                  ? 
                    <>
                      <label className="mx-3 mt-2">Is today essentially the same as yesterday?</label>
                      <div className="col-12">
                        {sameAsYesterdayAnswers.filter(showCode).toSorted(sortCode).map((sameAsYesterdayAnswer) => (
                          <label
                            key={sameAsYesterdayAnswer.id}
                            className={`btn btn-grey mr-1 ${sameAsYesterdayAnswer.id == sameAsYesterdayAnswerId && 'active'}`}
                            onClick={() => toggleCode('same_as_yesterday_answer_id', sameAsYesterdayAnswer.id)}
                          >
                            <span>{sameAsYesterdayAnswer.name}</span>
                          </label>
                        ))}
                      </div>

                      <label className="mx-3 mt-2">Point of Change</label>
                      <div className="col-12">
                        {pointOfChangeAnswers.filter(showCode).toSorted(sortCode).map((pointOfChangeAnswer) => (
                          <label
                            key={pointOfChangeAnswer.id}
                            className={`btn btn-grey mr-1 ${pointOfChangeAnswer.id == pointOfChangeAnswerId && 'active'}`}
                            onClick={() => toggleCode('point_of_change_answer_id', pointOfChangeAnswer.id)}
                          >
                            <span dangerouslySetInnerHTML={{ __html: pointOfChangeAnswer.name }} />
                          </label>
                        ))}
                      </div>
                    </>
                  : null
                }

                <div className="vdrs-step mt-3 col-12 d-flex">
                  <label className="continue mt-1">
                    <button className="btn" onClick={submitForm}>Submit</button>
                  </label>
                  <div className="ml-2 align-text-bottom">
                    <label
                      className="d-flex ml-4"
                      onClick={() => form.setValue('forgot_day', !forgotDay) }
                    >
                      <div className={`mt-2 checkbox ${forgotDay ? 'checked' : null}`} />
                      <u className="ml-3">Forgot/unsure of observation?</u>
                    </label>
                  </div>
                </div>
              </div>
            : null
          }
      </form>
    </div>
  );
}
